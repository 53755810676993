import { testIsLimitData, testIsRawConstraintStatus } from './test';
import { getAssertMessage } from '../../../lib/test-and-assert/get-assert-message';
import { assertIsArray } from '../../../lib/test-and-assert/assert-base';

/**
 * @param {RawConstraintStatus} rawConstraintStatus
 */
function assertIsRawConstraintStatus(rawConstraintStatus) {
    if (!testIsRawConstraintStatus(rawConstraintStatus)) {
        throw new Error(getAssertMessage('not valid rawConstraintStatus'));
    }
}

/**
 * @param {LimitData} limitData
 */
function assertIsLimitData(limitData) {
    if (!testIsLimitData(limitData)) {
        throw new Error(getAssertMessage('not valid LimitData'));
    }
}

/**
 * @param {LimitData[]} arrayOfLimitData
 */
function assertIsArrayOfLimitData(arrayOfLimitData) {
    assertIsArray(arrayOfLimitData);

    try {
        arrayOfLimitData.forEach((limitData) => {
            assertIsLimitData(limitData);
        });
    } catch (e) {
        throw new Error(getAssertMessage('not valid array of LimitData'));
    }
}

export { assertIsRawConstraintStatus };
export { assertIsLimitData };
export { assertIsArrayOfLimitData };
