/**
 * @return {String}
 */
import { testIsNonEmptyString } from '../../../../lib/test-and-assert/test-base';

function getLocationHashWithoutModalPageFragment() {
    const clearedLocationHash = window.location.hash.replace(/mp=[\w\-_]+&?/, '');

    if (testIsNonEmptyString(clearedLocationHash.replace('#', ''))) {
        // location hash still has at least one fragment value
        // after removing modal fragments
        return clearedLocationHash;
    }

    // location hash is empty without modal fragments
    return '';
}

export { getLocationHashWithoutModalPageFragment };
