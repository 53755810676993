import { testIsNonEmptyString } from '../../../lib/test-and-assert/test-base';
import { userManager } from '../../user/user-manager';
import { defineStore } from 'pinia';

export const useQuestionnaireModalStore = defineStore('questionnaireModalStore', {
    state: () => ({
        isSubmitting: false,
        openPage: null,
        lastOpenPage: null,
    }),
    getters: {
        getOpenPage(state) {
            return state.openPage;
        },
        getSubmitting(state) {
            return state.isSubmitting;
        },
    },
    actions: {
        async openOnPage(page) {
            await userManager.getUser();
            this.openPage = page;
        },
        close() {
            this.lastOpenPage = this.openPage;

            this.openPage = null;
        },
        changePage(payload) {
            this.openPage = payload.toPage;

            if (testIsNonEmptyString(payload.fromPage)) {
                this.lastOpenPage = payload.fromPage;
            }
        },
        setSubmitting(isSubmitting) {
            this.isSubmitting = isSubmitting;
        },
    },
});
