import { defineStore } from 'pinia';

export const useUploaderStore = defineStore('uploaderStore', {
    state: () => ({
        /** @type {?DisplayLimitError} */
        displayLimitError: null,
    }),
    getters: {
        getDisplayLimitError(state) {
            return state.displayLimitError;
        },
    },
    actions: {
        setDisplayLimitError(displayLimitError) {
            this.displayLimitError = displayLimitError;
        },
    },
});
