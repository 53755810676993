/**
 * @param {RawConstraintStatus} rawConstraintStatus
 */
import { testIsObject, testObjectHasKeys } from '../../../lib/test-and-assert/test-base';

function testIsRawConstraintStatus(rawConstraintStatus) {
    if (!testIsObject(rawConstraintStatus)) {
        return false;
    }

    return testObjectHasKeys(rawConstraintStatus, ['ident', 'type', 'hit']);
}

/**
 * @param {LimitData} limitData
 *
 * @return {boolean}
 */
function testIsLimitData(limitData) {
    if (!testIsObject(limitData)) {
        return false;
    }

    return testObjectHasKeys(limitData, ['ident', 'triggeredBy', 'type', 'ttl', 'hit', 'expiresAt']);
}

export { testIsRawConstraintStatus };
export { testIsLimitData };
