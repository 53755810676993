import Vue from 'vue';
import { createPinia, defineStore, PiniaVuePlugin } from 'pinia';
import { useLimitsStore } from './modules/limits-store';
import { useUserRegisterPaymentModalStore } from './modules/user-register-payment-modal-store';
import { useQuestionnaireModalStore } from './modules/questionnaire-modal-store';
import { useUploaderStore } from './modules/uploader-store';
import { useUserStore } from './modules/user-store';
import { useJobStore } from './modules/job-store';

export function initPiniaPageStore() {
    Vue.use(PiniaVuePlugin);

    // hacky, yes, but pinia needs a vue instance to function so...
    new Vue({ pinia: getPiniaInstance() });
}

export function getPiniaInstance() {
    if (!global.gPiniaInstance) {
        global.gPiniaInstance = createPinia();
    }

    return global.gPiniaInstance;
}

export const usePageStore = defineStore('pageStore', () => {
    return {
        user: useUserStore(),
        job: useJobStore(),
        limits: useLimitsStore(),
        questionnaireModal: useQuestionnaireModalStore(),
        userPaymentModal: useUserRegisterPaymentModalStore(),
        uploader: useUploaderStore(),
    };
});

export const pageStoreMixin = {
    setup() {
        return {
            store: usePageStore(),
        };
    },
};
