import { defineStore } from 'pinia';

export const useJobStore = defineStore('jobStore', {
    state: () => ({
        constraints: null,
        operation: null,
    }),
    getters: {
        getConstraints(state) {
            return state.constraints;
        },
        getOperation(state) {
            return state.operation;
        },
    },
    actions: {
        setConstraints(constraints) {
            this.constraints = constraints;
        },
        setOperation(operation) {
            this.operation = operation;
        },
    },
});
