import { assertIsArrayOfLimitData } from '../../limits/new/assert';
import { defineStore } from 'pinia';

export const useLimitsStore = defineStore('limitsStore', {
    state: () => ({
        limits: [],
    }),
    getters: {
        getLimits(state) {
            return state.limits;
        },
    },
    actions: {
        addLimits(origin, /** LimitData[] */ limits) {
            assertIsArrayOfLimitData(limits);

            /** @type {StoreEntryLimit} limitsObject */
            const limitsObject = {
                origin: origin,
                limits: limits,
            };

            this.limits.push(limitsObject);
        },
        addLimitsRefresh(/** LimitData[] */ limits) {
            this.addLimits(LIMITS_ORIGIN_REFRESH, limits);
        },
        addLimitsPageLoad(/** LimitData[] */ limits) {
            this.addLimits(LIMITS_ORIGIN_PAGE_LOAD, limits);
        },
        addLimitsUploaderEvent(/** LimitData[] */ limits) {
            this.addLimits(LIMITS_ORIGIN_UPLOADER_EVENT, limits);
        },
        addLimitsStartConversion(/** LimitData[] */ limits) {
            this.addLimits(LIMITS_ORIGIN_START_CONVERSION, limits);
        },
    },
});

export const LIMITS_ORIGIN_REFRESH = 'from-refresh';
export const LIMITS_ORIGIN_PAGE_LOAD = 'from-page-load';
export const LIMITS_ORIGIN_UPLOADER_EVENT = 'from-uploader-event';
export const LIMITS_ORIGIN_START_CONVERSION = 'from-start-conversion';
